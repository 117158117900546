<template>
  <div class="bb-breadcrumb">
    <span
      v-for="item in breadcrumbs"
      :key="item.to"
      class="bb-breadcrumb__item"
      :class="!item.to ? 'bb-breadcrumb__item--last' : ''"
    >
      <router-link v-if="item.to" :to="item.to">{{ item.name }}</router-link>
      <span v-else v-html="item.name"></span>
    </span>
  </div>
</template>
<script>
export default {
  name: "PageBreadcrumb",
  props: {
    breadcrumbs: {
      type: Array,
      required: true,
    },
  },
};
</script>
