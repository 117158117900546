import {mapGetters} from "vuex";
import FindCategories from "../../catalogue/components/mixins/FindCategories";

export default {
  mixins: [
    FindCategories
  ],
  computed: {
    ...mapGetters({
      selectedCategory: "selectedCategory",
    }),
    category() {
      return this.findCategoryById(this.selectedCategory?.mainCategoryId)
    },
    categoryName() {
      return this.category?.name
    },
    subCategoryName() {
      if (parseInt(this.selectedCategory?.level) === 1) {
        return null
      }
      if (parseInt(this.selectedCategory?.level) ===2) {
        return this.selectedCategory.name
      }
      if (parseInt(this.selectedCategory?.level) ===3) {
        // TODO HACK BRUT -> subCategories a nivell 3 conté el pare i no pas les subcategories (que ho hi han el últim nivell és el 3)
        return this.selectedCategory.subCategories?.name
      }
      return null
    },
    subSubCategoryName() {
      if (parseInt(this.selectedCategory?.level) ===3) {
        // HACK BRUT -> subCategories a nivell 3 conté el pare i no pas les subcategories (que no hi ha l'últim nivell és el 3).
        return this.selectedCategory?.name
      }
      return null
    },
  }
}