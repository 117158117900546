<template>
  <div v-if="show">
    <page-breadcrumb :breadcrumbs="breadcrumbWithCatalog"/>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import PageBreadcrumb from "../../pages/components/PageBreadcrumb";

export default {
  name: "CatalogueBreadcrumb",
  components: {
    'PageBreadcrumb': PageBreadcrumb
  },
  computed: {
    ...mapGetters({
      breadcrumb: "breadcrumb",
      selectedCategory: "selectedCategory"
    }),
    breadcrumbWithCatalog() {
      return [...[{
        name: "Catálogo",
        to: "/catalogo",
      }],
        ...this.categoryBreadCrumb,
        ...[{
          name: this.selectedCategory?.name,
        }]
      ];
    },
    categoryBreadCrumb() {
      if (!this.breadcrumb) return []
      return this.breadcrumb?.map(item => { return { name: item.name, to: item.to || '/categoria/' + item.slug}  })
    },
    show() {
      return this.selectedCategory
    },
  }
}
</script>