import {mapGetters} from "vuex";
import HasCategoryNames from "./HasCategoryNames";

export default {
  mixins: [
    HasCategoryNames
  ],
  computed: {
    ...mapGetters({
      selectedCategory: "selectedCategory",
      breadcrumb: "breadcrumb"
    }),
    breadcrumbArray() {
      let res = [];
      if (this.breadcrumb == null || this.breadcrumb == undefined) return res;
      if(parseInt(this.selectedCategory?.level) === 1)
      {
          res.push({
              "@type": "ListItem",
              position: 1,
              name: this.selectedCategory?.name
          });
          return res;
      }
      res.push({
          "@type": "ListItem",
          position: 1,
          name: this.getBreadCrumbElement('name'),
          item: this.$utils.removeDoubleSlashes(process.env.APP_URL + "/categoria/" + this.getBreadCrumbElement('slug'))
      });
      if(parseInt(this.selectedCategory?.level) === 2)
      {
        res.push({
            "@type": "ListItem",
            position: 2,
            name: this.selectedCategory.name
        })   
      }
      if(parseInt(this.selectedCategory?.level) === 3)
      {    
        res.push({
            "@type": "ListItem",
            position: 2,
            name: this.getBreadCrumbElement('name',1),
            item: this.$utils.removeDoubleSlashes(process.env.APP_URL + "/categoria/" + this.getBreadCrumbElement('slug',1))
        })
        res.push({
            "@type": "ListItem",
            position: 3,
            name: this.selectedCategory?.name,
        })  
      }
      return res;
  },
  },
  methods: {
    getBreadCrumbElement(element, position = 0) {
      if (this.checkArray(this.breadcrumb, position)) {
        return this.breadcrumb[position][element]
      }
    },
    checkArray(array, position = 0) {
      return array && Array.isArray(array) && typeof array[position] !== 'undefined'
    },
    scriptBreadCrumbList() {
      return [
        {
          type: 'application/ld+json',
          json:
            {
              "@context": "https://schema.org/",
              "@type": "BreadcrumbList",
              "itemListElement": this.breadcrumbArray
            }
          }
      ]
    }
  }
}