<template>
  <header class="g-catalogo__title">
    <h1 class="g-catalogo__title-header">
      {{ this.collectionName }}
    </h1>
    <div
        class="g-catalogo__title-description"
        v-if="collection.description"
        v-html="collection.description"
    ></div>
  </header>
</template>

<script>
export default {
  name: "CollectionHeader",
  props: {
    collection: {
      type: Object,
      required: true
    }
  },
  computed: {
    collectionName() {
      return this.collection?.name;
    },
  }
}
</script>