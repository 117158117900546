<template>
  <div>
    <catalogue-header v-if="displayHeader" />

    <catalogue-searchbar v-if="displaySearchBar" />

    <div class="category-page category-page--wb">
      <div class="bb-container">
        <catalogue-breadcrumb v-if="showBreadcrumb" :class="breadcrumbClass" />
        <div class="g-catalogo">
          <catalogue-categories-nav />

          <div v-if="collection" class="g-catalogo__list">
            <main>
              <collection-header :collection="collection" />
              <h2 class="g-catalogue-footer__title">
                {{ collection.catalogueTitle }}
              </h2>
              <catalogue-filters />
              <applied-filters />
              <catalogue-products
                getter="collectionProducts"
                :parameters="parameters"
                ref="catalogueProducts"
                :route="currentRoute"
              />
            </main>
            <footer v-if="collection" class="g-catalogue-footer">
              <LazyHydrate when-visible>
                <entity-collections :entity="collection"></entity-collections>
              </LazyHydrate>

              <LazyHydrate when-visible>
                <div
                  class="g-catalogue-footer__section"
                  v-if="collection.posts && collection.posts.length > 0"
                >
                  <h5 class="g-catalogue-footer__title">Artículos relacionados</h5>
                  <div class="g-catalogue-footer__content">
                    <div class="g-post">
                      <related-posts :posts="collection.posts"></related-posts>
                    </div>
                  </div>
                </div>
              </LazyHydrate>

              <div class="g-catalogue-footer__section" v-if="collection.content">
                <div class="g-catalogue-footer__content">
                  <div class="post-entry" v-html="collection.content"></div>
                </div>
              </div>
            </footer>
            <LazyHydrate when-visible>
              <CtaPregnant class="cta-pregnant--catalog" />
            </LazyHydrate>
          </div>
        </div>
      </div>
    </div>
    <LazyHydrate when-visible>
      <catalogue-porque />
    </LazyHydrate>

    <LazyHydrate when-visible>
      <categories-directory></categories-directory>
    </LazyHydrate>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CanFilterProducts from "../mixins/CanFilterProducts";
import CatalogueHeader from "../catalogue/components/CatalogueHeader";
import LazyHydrate from "vue-lazy-hydration";
import CatalogueProducts from "../catalogue/components/CatalogueProducts";
import CatalogueFilters from "../catalogue/components/CatalogueFilters";
import AppliedFilters from "../../components/AppliedFilters";
import CatalogueBreadcrumb from "../catalogue/components/CatalogueBreadcrumb";
import CatalogueCategoriesNav from "../catalogue/components/CatalogueCategoriesNav";
import DisplayHeader from "../mixins/DisplayHeader";
import DisplaySearchBar from "../mixins/DisplaySearchBar";
import CollectionHeader from "./components/CollectionHeader";
import CanGetCategories from "../../Mixins/CanGetCategories";
import HasBreadcrumbListMetaInfo from "../category/mixins/HasBreadcrumbListMetaInfo";

export default {
  name: "CollectionPage",
  mixins: [
    CanFilterProducts,
    DisplayHeader,
    DisplaySearchBar,
    CanGetCategories,
    HasBreadcrumbListMetaInfo,
  ],
  metaInfo() {
    return {
      title: this.$cutText(this.collection?.title, 70, "...") + " | HelloBB",
      meta: [
        {
          name: "description",
          content: this.$cutText(this.collection?.description, 160, "..."),
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.hellobb.net" + this.$route.path,
        },
      ],
    };
  },
  components: {
    CollectionHeader,
    CatalogueCategoriesNav,
    CatalogueProducts,
    CatalogueFilters,
    AppliedFilters,
    CatalogueHeader,
    CatalogueBreadcrumb,
    EntityCollections: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "EntityCollections" */ "../catalogue/components/EntityCollections"
      ),
    CtaPregnant: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "CtaPregnant" */ "../cta/CtaPregnant.vue"
      ),
    CatalogueSearchbar: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "CatalogueSearchbar" */ "../../components/CatalogueSearchbar.vue"
      ),
    CategoriesDirectory: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "CategoriesDirectory" */ "../catalogue/components/CategoriesDirectory"
      ),
    CataloguePorque: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "CataloguePorque" */ "../catalogue/components/CataloguePorque"
      ),
    RelatedPosts: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "RelatedPosts" */ "../catalogue/components/RelatedPosts"
      ),
    LazyHydrate,
  },
  data() {
    return {
      orderBy: "relevance",
      currentRoute: null,
    };
  },
  computed: {
    ...mapGetters({
      collection: "collection",
      collectionsProducts: "collectionProducts",
      collectionProductsPrefetched: "collectionProductsPrefetched",
      selectedCategory: "selectedCategory",
      savedOrderBy: "orderBy",
    }),
    breadcrumbClass() {
      // TODO David estil TODO ha de ser un estil per a quan no hi ha breadcrumbs! Ara mateix està apegat falta espai
      return this.showBreadcrumb ? "category-page__breadcrumbs" : "TODO";
    },
    // TODO definir com fer els breadcrumbs -> categories no té gaire sentit
    showBreadcrumb() {
      return this.selectedCategory && !this.$utils.isEmpty(this.selectedCategory);
    },
    parameters() {
      return {
        collectionid: this.collection?.id,
        page: 1,
        order: this.orderBy,
      };
    },
  },
  methods: {
    async getCollectionProducts(server = false) {
      this.orderBy = this.savedOrderBy;
      let parameters = { ...this.parameters, ...{ server: server } };
      parameters = this.applyFilters(parameters, false);
      return this.$store.dispatch("getCollectionProducts", parameters);
    },
    async getCollection() {
      await this.$store.dispatch("getCollection", this.$route.params.id);
    },
  },
  async serverPrefetch() {
    await this.getCollection();
    await this.getCollectionProducts(true);
  },
  created() {
    this.currentRoute = this.$route;
  },
  async mounted() {
    if (!this.collection) {
      await this.getCollection();
    } else if (parseInt(this.collection.id) !== parseInt(this.$route.params.id)) {
      await this.getCollection();
    }
    if (!this.collectionProductsPrefetched) {
      await this.getCollectionProducts(false);
    }
  },
  async beforeRouteUpdate(to, from, next) {
    this.currentRoute = to;
    await this.getCollection();
    await this.getCollectionProducts(false);
    next();
  },
};
</script>
